<template>
  <html lang="ja">
    <head>
      <link href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900" rel="stylesheet">
        <link href="https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css" rel="stylesheet">
        <link href="https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css" rel="stylesheet">
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, minimal-ui">
      <title>Top</title>
    </head>
    <body>
      <div id="app">
        <v-app id="inspire">    
          <!-- ヘッダー -->
          <v-app-bar 
            app
            dense
            color="#fcb69f"
            :src= headImg.content
          >
          <v-toolbar-title>Nteime</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- info -->
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
          		<v-btn icon v-bind="attrs" v-on="on">
            		<v-icon>mdi-dots-vertical</v-icon>
          		</v-btn>
						</template>
						<v-card >
							<v-card elevation="1" width="250px" class="pa-5"> 
									<img :src= profileImg.content>
									<v-card-title class="justify-center">ていめ</v-card-title>
									<v-card-text class="justify-center">blenderなどを使って映像作品を作っています<br>
									かわいいもの、おはなとすこし悲しい感じ</v-card-text>
									<v-btn icon href="https://twitter.com/min_minsenn">
										<v-icon>mdi-twitter</v-icon>
									</v-btn>
									<v-btn icon href="https://www.instagram.com/teime616">
										<v-icon>mdi-instagram</v-icon>
									</v-btn>
									<v-btn icon @click="mail()">
										<v-icon>mdi-send</v-icon>
									</v-btn>
							</v-card>
						</v-card>
					</v-menu>
          </v-app-bar>
          <!-- メインコンテンツ -->
			    <v-main>
					    	<v-container>
						      <v-row justify="center" align-content="center">
						      	<v-col cols="12">
                      <v-card elevation="3">
                        <v-container>
                          <img :src="contents.content">
                          <p>たいとる: {{contents.title}}</p>
                          <p>公開日: {{contents.entry_ts}}
                          <p>使ったもの: {{contents.useTool}}</p>
                          <p>かかった時間: {{ contents.time }}</p>
                        </v-container>
                      </v-card>
									  </v-col>
								  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-btn tile elevation="0" outlined @click="goHome()" text-h5 >
													modoru
											</v-btn>
                    </v-col>
                  </v-row>
							  </v-container>
			    </v-main>
			  </v-app>
      </div>
    </body>
  </html>
</template>
	
<script>
// import Subject_ele from "../views/Subject_ele.vue";
// import Subject_high from "../views/Subject_high.vue";
// import Subject_jnr from "../views/Subject_jnr.vue";
// import Router from '../router/index.js';

export default{
  data: function() {
    return{
      headImg:{id: 1, content: require("@/assets/img/20210119.png")},
      id:"",
      contents:{id: "", title: '', entry_ts: '', time: '', useTool: '', content: ''},
      reroadcontents:{},
      profileImg:{id: 1, content: require("@/assets/img/profile.png")},
      page:""
    };
  },
  mounted(){
    this.reroadcontents= JSON.parse(decodeURIComponent(this.$route.query.param));
    this.contents.id = this.reroadcontents.id;
    this.contents.title = this.reroadcontents.title;
    this.contents.entry_ts = this.reroadcontents.entry_ts;
    this.contents.time = this.reroadcontents.time;
    this.contents.useTool = this.reroadcontents.useTool;
    this.contents.content = this.reroadcontents.content;
    this.page = JSON.parse(decodeURIComponent(this.$route.query.page));
  },
  methods: {
    goHome(){
      this.$router.push({
        name: "Home",
        query:{
          param: encodeURIComponent(JSON.stringify(this.page)),
        },
      });
    }
  }
};
</script>
<style>
  #app {
    width: 100%;
   	background-color: gray;
  }

  img {
    max-height: 50%;
		max-width: 80%;
		height: auto;
		width: auto;
  }
</style>
