<template>
  <html lang="ja">
    <head>
      <link href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900" rel="stylesheet">
        <link href="https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css" rel="stylesheet">
        <link href="https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css" rel="stylesheet">
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, minimal-ui">
      <title>Top</title>
    </head>
    <body>
      <div id="app">
        <v-app id="inspire">    
          <!-- ヘッダー -->
          <v-app-bar 
						app
            dark
            shrink-on-scroll
            :src= headImg.content
          >
          <v-toolbar-title>Nteime</v-toolbar-title>
          <v-spacer></v-spacer>

					<!-- info -->
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
          		<v-btn icon v-bind="attrs" v-on="on">
            		<v-icon>mdi-dots-vertical</v-icon>
          		</v-btn>
						</template>
						<v-card >
							<v-card elevation="1" width="250px" class="pa-5"> 
									<img :src= profileImg.content>
									<v-card-title class="justify-center">ていめ</v-card-title>
									<v-card-text class="justify-center">blenderなどを使って映像作品を作っています<br>
									かわいいもの、おはなとすこし悲しい感じ</v-card-text>
									<v-btn icon href="https://twitter.com/min_minsenn">
										<v-icon>mdi-twitter</v-icon>
									</v-btn>
									<v-btn icon href="https://www.instagram.com/teime616">
										<v-icon>mdi-instagram</v-icon>
									</v-btn>
									<v-btn icon @click="mail()">
										<v-icon>mdi-send</v-icon>
									</v-btn>
							</v-card>
						</v-card>
					</v-menu>

					<!-- tab -->
          <template v-slot:extension>
						<v-tabs
							v-model="tab"
							align-with-title
						>
							<v-tabs-slider color="yellow"></v-tabs-slider>
							<v-tab href="#tab-1">app</v-tab>
							<v-tab href="#tab-2">movie</v-tab>
							<v-tab href="#tab-3">blog</v-tab>
						</v-tabs>
					</template>
          </v-app-bar>
          <!-- メインコンテンツ -->
					<div v-cloak>
			    <v-main>
			    	<v-tabs-items v-model="tab">
			    		<!-- タブ1 -->
			    		<v-tab-item value="tab-1">
					    	<v-container>
						      	<v-row>
						      		<v-col cols="12">
						      			<v-card flat elevation="1" height="400" >
									        <v-card-text align="center">準備中です</v-card-text>
													<img :src= roadImg.content>
										</v-card>
									</v-col>
								</v-row>
							</v-container>
			    		</v-tab-item>
			    		
			    		<!-- タブ2 -->
			    		<v-tab-item value="tab-2">
					    	<v-container>
						      	<v-row>
						      		<v-col cols="12">
									        <v-container>
									        	<v-row>
									        		<v-col cols="12" xs="12" sm="6" md="4" lg="4" v-for = "article in displayLists" :key="article.id" pr-1 pl-1 mr-n2 ml-n2>
									        			<v-container>
									        				<v-hover v-slot="{ hover }">
										        				<v-card 
										        					elevation="3" 
										        					width="100%"
																			class="base"
										        				>
										        					<v-row justify="center" align-content="center">
											        					<v-col cols="12">
																					<v-container>
																						<v-row vertical-align="middle" align-content="center">
																							<v-col cols="12">
																								<!-- 下画像 -->
																								<img :data-src="article.content" class="lazyload">
																								<p>{{ article.entry_ts }}</p>
																							</v-col>
																						</v-row>
																					</v-container>
																					<v-expand-transition>
																						<div v-if="hover"
																							class="d-flex 
																							transition-fast-in-fast-out 
																							v-card--reveal 
																							text-h5 
																							white--text"
																							style="height: 100%;"
																						>
																							<v-container>
																								<v-row>
																									<v-col cols="12">
																										<v-card color="rgb(204, 204, 204, 0.8)" width="100%" pa-6>
																											<v-container pl-6 pr-6 pt-1 pb-1 mt-0 mb-0 >
																												<v-row>
																													<v-col cols="12" >
																														<p class="text-subtitle-1">title: {{ article.title }}</p>
																														<p class="text-subtitle-1">time: {{ article.time }}</p>
																														<p class="text-subtitle-1">Tool: {{ article.useTool }}</p>
																														<v-btn color="white" tile elevation="0" outlined @click="goDetail(article.id)" text-h5 width="100%" >
																															詳細
																														</v-btn>
																														<p></p>
																													</v-col>
																												</v-row>
																											</v-container>
																										</v-card>
																									</v-col>
																								</v-row>
																							</v-container>
																				</div>
																			</v-expand-transition>
												        				</v-col>
											        				</v-row>
											        			</v-card>
										        			</v-hover>
										        		</v-container>
									        		</v-col>
									        	</v-row>
									        </v-container>
									</v-col>
								</v-row>
							</v-container>
						</v-tab-item>
						
						<!-- タブ3 -->
			    		<v-tab-item value="tab-3">
					    	<v-container>
						      	<v-row>
						      		<v-col cols="12">
						      			<v-card flat elevation="1" height="400" >
									        <v-card-text align="center">準備中です</v-card-text>
													<img :src= roadImg2.content>
										</v-card>
									</v-col>
								</v-row>
							</v-container>
						</v-tab-item>
					</v-tabs-items>
					<v-main>
              <div class="text-center">
                <v-pagination
                  v-model="page"
                  :length="length"
                  @input="pageChange"
                ></v-pagination>
              </div>
            </v-main>
			    </v-main>
					</div>
			  </v-app>
      </div>
    </body>
  </html>
</template>
	
<script>
// import Subject_ele from "../views/Subject_ele.vue";
// import Subject_high from "../views/Subject_high.vue";
//import Detail from "../views/Detail.vue";
//import Router from '../router/index.js';
import 'lazysizes';

export default{
  data: function() {
    return{
			param:"",
			page1:"",
			page: 1,
      length: 0,
      pageSize: 10,
			displayLists: [],
      articleList1: [	    
	    ],
			headImg:{id: 1, content: require("@/assets/img/20210119.png")},
			roadImg:{id: 1, content: require("@/assets/img/road.png")},
			roadImg2:{id: 1, content: require("@/assets/img/road2.png")},
			profileImg:{id: 1, content: require("@/assets/img/profile.png")},
	    isChangeHeight: true,
	    tab: 'tab-2',
			sendList:{},
			address1: 'nteime228',
      address2: '@gmail.com',
      address: this.address1+this.address2,
      subject: '○○の件について',
      body: 'メール本文' + '%0D%0A', 
    };
  },
  mounted(){
			if(this.$route.query.param!=null){
			this.page1 = JSON.parse(decodeURIComponent(this.$route.query.param));
			if(this.page1 != 0 || this.page != null){
				this.page = this.page1;
			}
			}
		this.$nextTick(function(){
			this.articleList1=[
				{id: 34, title: 'ハロウィン', entry_ts: '2021-10-31', time: '2d', useTool: 'blender', content: require("@/assets/img/20211031.gif")},
				{id: 33, title: 'いきてる', entry_ts: '2021-10-18', time: '3d', useTool: 'blender', content: require("@/assets/img/20211018.gif")},
				{id: 32, title: 'のこぎり', entry_ts: '2021-10-08', time: '1h', useTool: 'blender', content: require("@/assets/img/20211008.gif")},
				{id: 31, title: 'もみじ', entry_ts: '2021-10-02', time: '1h', useTool: 'blender', content: require("@/assets/img/20211002.gif")},
				{id: 30, title: 'ぜったいほしい', entry_ts: '2021-09-24', time: '1h', useTool: 'blender', content: require("@/assets/img/20210924.gif")},
			  {id: 29, title: 'かわいい', entry_ts: '2021-09-12', time: '2d', useTool: 'blender', content: require("@/assets/img/20210912.gif")},
				{id: 28, title: 'せんたくちゅう', entry_ts: '2021-08-18', time: '1h', useTool: 'blender', content: require("@/assets/img/20210818.gif")},
				{id: 27, title: 'じぐそーぱずる', entry_ts: '2021-08-14', time: '1h', useTool: 'blender', content: require("@/assets/img/20210814.gif")},
				{id: 26, title: 'かんざし', entry_ts: '2021-08-13', time: '1h', useTool: 'blender', content: require("@/assets/img/20210813.gif")},
				{id: 25, title: 'ごはん', entry_ts: '2021-08-12', time: '1h', useTool: 'blender', content: require("@/assets/img/20210812.gif")},
				{id: 24, title: 'といれ', entry_ts: '2021-08-03', time: '1h', useTool: 'blender', content: require("@/assets/img/20210803.gif")},
				{id: 23, title: 'ほうき', entry_ts: '2021-07-29', time: '1h', useTool: 'blender', content: require("@/assets/img/20210729.gif")},
				{id: 22, title: 'とけい', entry_ts: '2021-07-28', time: '1h', useTool: 'blender', content: require("@/assets/img/20210728.gif")},
				{id: 21, title: 'えき', entry_ts: '2021-07-22', time: '1h', useTool: 'blender', content: require("@/assets/img/20210722.gif")},
				{id: 20, title: 'たこ', entry_ts: '2021-07-16', time: '1h', useTool: 'blender', content: require("@/assets/img/20210716.gif")},
				{id: 19, title: 'なつ', entry_ts: '2021-06-19', time: '2d', useTool: 'blender', content: require("@/assets/img/20210619.gif")},
				{id: 18, title: 'まぶしい', entry_ts: '2021-06-16', time: '1h', useTool: 'blender', content: require("@/assets/img/20210616.gif")},
				{id: 17, title: 'あいすきゃんでー', entry_ts: '2021-06-01', time: '1h', useTool: 'blender', content: require("@/assets/img/20210601.gif")},
				{id: 16, title: 'れんが', entry_ts: '2021-05-30', time: '1h', useTool: 'blender', content: require("@/assets/img/20210530.gif")},
				{id: 15, title: 'はちみつ', entry_ts: '2021-05-12', time: '1h', useTool: 'blender', content: require("@/assets/img/20210512.gif")},
				{id: 14, title: 'ばけつ', entry_ts: '2021-05-11', time: '1h', useTool: 'blender', content: require("@/assets/img/20210511.gif")},
				{id: 13, title: 'りんご', entry_ts: '2021-05-10', time: '1h', useTool: 'blender', content: require("@/assets/img/20210510.gif")},
				{id: 12, title: 'ぷーる', entry_ts: '2021-04-22', time: '1h', useTool: 'blender', content: require("@/assets/img/20210422.gif")},
				{id: 11, title: 'まかろん', entry_ts: '2021-03-21', time: '3h', useTool: 'blender', content: require("@/assets/img/20210321.gif")},
				{id: 10, title: 'さめさめ', entry_ts: '2021-03-17', time: '1h', useTool: 'blender', content: require("@/assets/img/20210317.gif")},
				{id: 9, title: 'さめ', entry_ts: '2021-03-14', time: '1h', useTool: 'blender', content: require("@/assets/img/20210314.gif")},
				{id: 8, title: 'ねぎ', entry_ts: '2021-03-09', time: '1h', useTool: 'blender', content: require("@/assets/img/20210309.gif")},
				{id: 7, title: 'りょうり', entry_ts: '2021-03-08', time: '2w', useTool: 'blender', content: require("@/assets/img/20210308.gif")},
				{id: 6, title: 'きえちゃえ', entry_ts: '2021-02-24', time: '1h', useTool: 'blender', content: require("@/assets/img/20210224.gif")},
				{id: 5, title: 'あらあぶない', entry_ts: '2021-02-18', time: '1h', useTool: 'blender', content: require("@/assets/img/20210218.gif")},
				{id: 4, title: 'びーだま', entry_ts: '2021-02-17', time: '1h', useTool: 'blender', content: require("@/assets/img/20210217.gif")},
				{id: 3, title: 'いちご', entry_ts: '2021-02-02', time: '1h', useTool: 'blender', content: require("@/assets/img/20210202.gif")},
				{id: 2, title: 'あんたのせいだ', entry_ts: '2021-02-01', time: '1h', useTool: 'blender', content: require("@/assets/img/20210201.gif")},
	      {id: 1, title: 'とうろう', entry_ts: '2021-01-20', time: '4h', useTool: 'blender', content: require("@/assets/img/20210120.gif")},
	   	];
			this.length = Math.ceil(this.articleList1.length / this.pageSize);
    	this.displayLists = this.articleList1.slice(
      this.pageSize * (this.page - 1),
      this.pageSize * this.page);

		
		})
  },
  methods: {
		pageChange: function (pageNumber) {
      this.displayLists = this.articleList1.slice(
        this.pageSize * (pageNumber - 1),
        this.pageSize * pageNumber
      );
    },
    goDetail(id){
			this.sendList = this.articleList1.find((v) => v.id === id);
      this.$router.push({
				name: "Detail",
				query:{
					param: encodeURIComponent(JSON.stringify(this.sendList)),
					id: id,
					page: this.page
				},
			});
    },
		mail(){
        this.address1 = 'nteime228';
        this.address2 = '@gmail.com';
        this.address = this.address1+this.address2;
        this.subject = '○○の件について';
        this.body = 'メール本文' + '%0D%0A'; 

        location.href = 'mailto:' + this.address + '?subject=' + this.subject + '&body=' + this.body;
		},
  }
};
</script>
<style>
 	.cardContent {
		align-items: center;
  	top: 0;
  	justify-content: center;
  	position: absolute;
		vertical-align: middle;
  }
  #app {
   	background-color: gray;
  }
  .v-card--reveal {
  	align-items: center;
  	top: 0;
  	justify-content: center;
  	position: absolute;
  	width: 100%;
	}
	img{
		max-height: 50%;
		max-width: 80%;
		height: auto;
		width: auto;
	}
	[v-cloak] {
  display: none;
	}
	@media screen and (max-width:2000px){
		.base{
		height: 330px;
		}
  }

	@media screen and (max-width:1247px){
		.base{
		height: 270px;
		}
  }

	@media screen and (max-width:950px){
		.base{
		height: 380px;
		}
  }
	@media screen and (max-width:700px){
		.base{
		height: 300px;
		}
  }
	@media screen and (max-width:600px){
		.base{
		height: 400px;
		}
  }
	@media screen and (max-width:500px) {
		.base{
		height: 350px;
		}
  }
	@media screen and (max-width:450px) {
		.base{
		height: 320px;
		}
  }
	@media screen and (max-width:300px) {
		.base{
		height: 300px;
		}
  }
	@media screen and (max-width:350px) {
		.base{
		height: 260px;
		}
  }
	@media screen and ( max-width:0px) {
		.base{
		height: 300px;
		}
	}
</style>
